.Board {
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

.Board__output {
  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  height: 112px;
  max-height: 15%;
  min-height: 70px;
  z-index: 1;
}

.hidden {
  display: none;
}

.Board__output button[class*='MuiButtonBase-'].scanner__focused {
  outline: 4px solid rgba(255, 0, 0, 1);
}

.Board__navbar {
  position: relative;
  right: 0;
  width: 100%;
  z-index: 2;
}

.Board__loading {
  color: rgb(121, 22, 254);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Board__edit-toolbar,
.Board__communicator-toolbar {
  position: relative;
  right: 0;
  width: 100%;
  z-index: 3;
}

.is-locked .Board__edit-toolbar,
.is-locked .Board__communicator-toolbar {
  display: none;
}

.Board__tiles {
  position: relative;
  height: 100%;
  z-index: 4;
  flex: 1;
  background: #fff;
  direction: ltr; /* for grid */
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.ScrollButtonsOnTheSides {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.ScrollButtonsOnTheSides::-webkit-scrollbar {
  display: none;
}

.is-dark .Board__tiles {
  background: #444;
}

.Board__tiles .Grid {
  margin-top: 0px;
}

.is-locked .Board__tiles .Grid {
  margin-top: 0;
}

.BoardSideButtonsContainer {
  display: flex;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  flex: 1 1;
  justify-content: center;
}
