.WelcomeScreen {
  height: 100%;
  padding: 1.5rem;
  position: relative;
  color: #eceff1;
  overflow: auto;
  background: /* webpackIgnore: true */ url('/images/bg/bg-pattern.png'),
    linear-gradient(
      to right,
      rgb(142, 142, 142),
      rgb(91, 91, 91),
      rgb(142, 142, 142)
    );
  background-blend-mode: color-burn;
}

.WelcomeScreen__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 680px;
  margin: 0 auto;
  text-align: center;
  font-family: 'Catamaran', 'Helvetica', 'Arial', 'sans-serif';
  letter-spacing: 1px;
}

.WelcomeScreen__container > button {
  width: 50px;
}

.WelcomeScreen__content {
  margin-left: 0.85rem;
  margin-right: 0.85rem;
}

.WelcomeScreen__heading {
  font-size: 60px;
  font-weight: 200;
  margin-block-end: 0.5rem;
  margin-block-start: 0.75rem;
  color: #eceff1 !important;
}

.WelcomeScreen__logo {
  flex-grow: 1;
  min-height: 0;
}

.WelcomeScreen__footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: calc(50% - 0.5em);
  min-width: 300px;
  margin: 0 auto 6%;
}

.WelcomeScreen__footer .WelcomeScreen__button {
  width: 100%;
  margin: 0.8em auto 0;
}

.WelcomeScreen__button--skip {
  margin: 1.2em 0.5em 0;
}

.WelcomeScreen__footer .WelcomeScreen__button--google,
.WelcomeScreen__footer .WelcomeScreen__button--facebook {
  margin: 0.8em auto 0 !important;
  height: 36px !important;
  font-size: 1.1rem !important;
}

.WelcomeScreen__links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 4px;
}

.WelcomeScreen__links > a {
  font-size: 0.8rem;
}

.WelcomeScreen__button--google div > span,
.WelcomeScreen__button--facebook div > span {
  display: inline-block;
  text-align: center;
  width: calc(100% - 26px);
}

.WelcomeScreen__button--google div > svg,
.WelcomeScreen__button--facebook div > svg {
  width: 22px;
  height: 22px;
}

.WelcomeScreen__button--google div:nth-child(2n),
.WelcomeScreen__button--facebook div:nth-child(2n) {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  height: 100%;
}
